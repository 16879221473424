
/* F&Q */

.faq-wrapper{
    width: 100%;
}
.faq-wrapper .entry{
    margin-top: 40px;
}
.faq {
    transition: all 0.3s ease-in;
    background-color: #FFFFFF;
    border-top: 1px solid #D1E8FF;
}
.faq:first-of-type{
    border-top: none;
}
.faq-wrapper .entry .spec-img{
    z-index: 1;
    position: relative;
}
.faq-title {
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    position: relative;
    padding: 40px;
    gap: 30px;
}

.faq-btn {
    background-size: 22px;
    background-repeat: no-repeat;
    background-position: center right;
    width: 42px;
    height: 22px;
    float: right;
}
.faq-btn:hover {
    cursor: pointer;
}

.faq.collapsed .faq-btn {
    background-image: url(../image/plus_icon.svg);
}

.faq.expanded .faq-btn {
    background-image: url(../image/minus_icon.svg);
}
.faq.collapsed .faq-body {
    display: none;
}

.faq.expanded{
    background: #271346;
    border-radius: 10px;
    border-top: none;
}

.faq.expanded+.faq{
    border-top: none;
}
.faq.expanded .faq-title h3, .faq.expanded .faq-body p{
    color: #FFFFFF;
}
.faq.expanded .faq-body{
    padding: 0px 40px 40px;
}
.faq.first-spec{
    margin-top: 0;
}
.faq .faq-title h3 {
    pointer-events: none;
}
.faq ul{
    list-style-type: square;
    margin-left: 20px;
}
.faq ul li{
    line-height: 40px;
}

@media (max-width: 868px) {
    #faq{
        padding-bottom: 40px;
    }
    .faq-wrapper .entry{
        margin-top: 20px;
    }
    .faq-title, .faq.expanded .faq-body{
        padding: 20px;
    }

}