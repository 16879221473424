html {
    box-sizing: border-box;
}

body {
    font-family: 'Lato';
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

h1,
h2,
h3 {
    font-style: normal;
    font-family: 'Mulish';
    font-weight: 800;
}

h1 {
    font-weight: 800;
    font-size: 64px;
    line-height: 70px;
}

h2 {
    font-weight: 800;
    font-size: 42px;
    line-height: 49px;
    margin-bottom: 30px;
    color: #281347;
}
.about h2,
.faq-wrapper h2 {
    font-size: 52px;
}
h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #2c395a;
}

p,
li {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: #675279;
}
p + p {
    margin-top: 30px;
}
ul {
    list-style: none;
}

a {
    text-decoration: none;
    font-weight: 700;
    color: #b15bff;
}

b,
strong {
    font-weight: 800;
}

img {
    max-width: 100%;
    height: auto;
}
@media (max-width: 868px) {
    h1 {
        font-size: 28px;
        line-height: 36px;
    }
    .about h2,
    .faq-wrapper h2 {
        font-size: 32px;
        line-height: 37px;
    }

    h2 {
        font-size: 26px;
        line-height: 30px;
    }
    h3 {
        font-size: 18px;
        line-height: 23px;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
    }
}
.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 5%;
}

.clearfix {
    clear: both;
    content: '';
}
.text-center {
    text-align: center;
}
.text-white {
    color: #e7e7e7;
}
.main-wrapper {
    position: relative;
    width: 100%;
    max-width: 3000px;
    margin: auto;
}

.scroll-to-link > * {
    pointer-events: none;
}

/* Banner */
.banner {
    background: #ffffff;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center;
    grid-template-columns: 0.8fr 2fr 0.7fr;
}
.banner .logo-box {
    background: #eaf2f8;
    border-radius: 10px;
}
.banner .logo-box a {
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    color: #090909;
    margin: 0;
    width: 100%;
    margin-bottom: 20px;
}
.banner .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7d7f82;
    position: relative;
    margin-left: 15px;
    width: 100%;
}
.banner .info:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #f27558;
}
.banner .domaner-btn {
    background: #f27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .banner .info {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
@media only screen and (max-width: 940px) {
    .banner {
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a {
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title {
        line-height: 24px;
        text-align: center;
    }
    .banner .info {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        margin-left: 0;
    }
    .banner .info:before {
        content: none;
    }
}
@media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
}

.site-header {
    display: block;
    background: url(../image/bg_hero.webp) no-repeat 0 0 / cover;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 15%;
    position: relative;
    overflow: hidden;
    margin-bottom: -100px;
}
.site-logo {
    font-size: 0;
    position: relative;
    background: url(../image/logo.svg) no-repeat 0 0 / contain;
    display: inline-block;
    font-size: 0;
    height: 67px;
    position: relative;
    vertical-align: middle;
    width: 296px;
}
.logo-wrapper {
    display: block;
    text-align: center;
    margin: 0 auto;
    padding: 30px 0;
}
.title {
    display: block;
    margin: 55px auto;
    width: 70%;
}
.info {
    display: block;
    margin: 0 auto;
    width: 75%;
}
.title span {
    display: block;
    font-weight: 300;
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    color: #ffffff;
}
.subtitle {
    font-weight: 800;
    font-size: 18px;
    line-height: 30px;
    margin-top: 40px;
}
.nav-buttons {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    margin-top: 90px;
}
ul.nav-buttons li {
    height: 60px;
    line-height: 60px;
    text-align: center;
    margin-top: 0;
}
ul.nav-buttons li a {
    font-family: 'Lato';
    font-style: normal;
    display: block;
    font-weight: 400;
    font-size: 17px;
    line-height: 56px;
    height: 60px;
    max-width: 100%;
    width: 270px;
    text-align: center;
    color: #ffffff;
    border: 2px solid #e5ecff;
    background: transparent;
    border-radius: 41px;
    transition: all 0.25s ease-in-out;
}
ul.nav-buttons li a:hover {
    background: #4c2371;
    box-shadow: 0px 19px 30px rgba(32, 0, 62, 0.52);
    color: #ffd1d9;
    border: 2px solid transparent;
}

@media (min-width: 1800px) {
    .site-header {
        padding: 0 15% 200px 15%;
    }
}
@media (max-width: 868px) {
    .site-header {
        background-position: top center;
        padding-bottom: 240px;
        margin-bottom: -160px;
    }
    .site-logo {
        background: url(../image/logo_mob.svg) no-repeat 0 0 / contain;
        width: 300px;
        max-width: 100%;
        height: 29px;
    }
    .title span {
        font-size: 20px;
        line-height: 50px;
    }
    .title {
        margin: 0 0 30px 0;
    }
    .title,
    .info {
        width: 100%;
    }
    .subtitle {
        font-size: 17px;
        line-height: 26px;
    }
    .nav-buttons {
        margin-top: 40px;
    }
}

.section-item {
    position: relative;
    display: flex;
    width: 100%;
    flex-basis: 100%;
    flex-flow: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 90px 5%;
    gap: 30px;
}
.image-wrapper .bg-image {
    position: relative;
}
.content-wrapper {
    flex-basis: 50%;
    padding-right: 5%;
}

@media (min-width: 1800px) {
    .section-item {
        padding-left: 15%;
        padding-right: 15%;
    }
}
@media (max-width: 868px) {
    .section-item {
        flex-direction: column;
        flex-wrap: wrap;
        padding-top: 40px;
    }
    .section-item#different-ways {
        padding-bottom: 40px;
    }
}

.what-are {
    padding: 0;
}
.what-are .inner {
    border: 2px solid #281347;
    background-color: #ffffff;
    padding: 15px;
    position: relative;
    display: flex;
    width: 100%;
    flex-basis: 100%;
    flex-flow: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    gap: 30px;
    max-width: 1350px;
    margin: 0 auto;
}
.what-are .content-wrapper {
    padding-top: 55px;
    padding-bottom: 40px;
}
.what-are .image-wrapper {
    display: block;
    background: url(../image/bg_what_are.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 32vw;
    width: 41vw;
    max-width: 100%;
}
@media (min-width: 2000px) {
    .what-are .image-wrapper {
        height: 22vw;
        width: 31vw;
    }
}
@media (min-width: 1800px) {
    .what-are {
        padding-left: 15%;
        padding-right: 15%;
        gap: 0;
    }
}
@media (max-width: 868px) {
    .what-are .image-wrapper {
        height: 45vw;
        width: 86vw;
    }
    .what-are .content-wrapper {
        padding-top: 0px;
        text-align: center;
    }
    .what-are .inner {
        flex-direction: column;
        flex-wrap: wrap;
    }
}

@media (max-width: 568px) {
    .what-are .content-wrapper {
        padding-top: 0px;
    }
}

.projects {
    padding: 0 5%;
    margin: 0 auto;
    display: block;
}
.project-listing {
    margin-bottom: 40px;
}
.project-listing .items {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    gap: 35px;
    margin-bottom: 20px;
}
.project-listing .items .slide {
    background: #ffffff;
    border: 1px solid #c2c2c2;
    flex: 0 0 23%;
    height: 124px;
    text-indent: -4000px;
    font-size: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
}
.project-listing .items .slide.filecoin {
    background-image: url(../image/Filecoin_logo.webp);
}
.project-listing .items .slide.axieinfinity {
    background-image: url(../image/Axie_Infinity_logo.webp);
}
.project-listing .items .slide.eqonex {
    background-image: url(../image/logo-eqonex.png);
}
.project-listing .items .slide.bat {
    background-image: url(../image/BAT_logo.webp);
}

@media (min-width: 1800px) {
    .projects {
        padding: 0 15%;
    }
}
@media (max-width: 1024px) {
    .projects {
        padding: 0;
    }
}

.future {
    background: #271346;
}
.about {
    padding-bottom: 0;
    align-items: stretch;
    height: 100%;
    padding-top: 0;
    gap: 60px;
}
.about .image-wrapper {
    max-width: 100%;
    width: 548px;
    height: 712px;
    background-image: url(../image/about.webp);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    margin: 0 auto;
}
.about .content-wrapper {
    flex: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
}

.btn-wrapper {
    margin: 40px 0;
}

.btn {
    display: block;
    background: #b15bff;
    border-radius: 87px;
    width: 273px;
    max-width: 100%;
    height: 81px;
    font-family: 'Mulish';
    font-weight: 800;
    font-size: 24px;
    line-height: 81px;
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    position: relative;
}
.copyright {
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #b1b1b1;
}
@media (max-width: 868px) {
    .about {
        padding-left: 0;
        padding-right: 0;
        gap: 0;
    }
    .about .image-wrapper {
        background-image: url(../image/about_mob.webp);
        height: 253px;
        width: 100%;
    }
    .about .content-wrapper {
        background: #ffffff;
        padding: 30px 15px;
        margin: -60px 5% 0 5%;
        align-items: center;
    }
    .about .content-wrapper h2,
    .about .content-wrapper p {
        text-align: center;
    }
    .copyright {
        text-align: center;
    }
}
