.slider-nav {
    opacity: 0;
    height: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px auto 0px;
    padding: 20px 0 0 0;
    width: 80px;
}
.slider-nav li {
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background-color: #B2CADA;
    transition: background-color 0.2s linear;
    cursor: pointer;
}

.slider-nav li.active {
    background-color: #B15BFF;
}

@media only screen and (max-width: 767px){
    .slider-nav{
       padding-top: 0px!important;
    }
}

@media (max-width: 1024px) {
    .slider-wrapper {
        overflow: hidden;
    }
    .slider-nav {
        opacity: 1;
        height: auto;
    }
    .project-listing .items{
        width: 2000px;
        justify-content: unset;
        gap: 10px;
    }
    .project-listing .items .slide{
        flex: inherit;
    }
    .slider-wrapper .slide{
        display: inline-block;
        width: 70vw;
        margin-right: 10px;
        transition: margin 0.2s linear;
    }
}
